* {
  /* font-family: 'Montserrat', sans-serif; */
  /* font-family: 'Shadows Into Light', cursive; */
  /* font-family: 'Amatic SC', cursive; */
  /* font-family: 'Warnes', cursive; */
  box-sizing: border-box;
}
.container-fluid {
  padding: 0;
}
/* LogoInicio */
.nombre a {
  font-family: 'Warnes', cursive;
}
.logo-inicio {
  margin-top: 1rem;
  font-size: 1.5rem;
  align-self: center;
}
.iconos {
  display: absolute;
  flex-direction: column;
  position: fixed;
  margin-top: 0.7em;
  left: 3em;
}
.iconos .col {
  display: flex;
  justify-content: center;
}
.iconos svg {
  /* max-width: 5rem; */
  height: 2em;
  margin: 0;
  cursor: pointer;
}
.iconos a {
  display: flex;
  max-width: fit-content;
  margin-top: -1.5em;
  max-width: 10em;
}
.email:hover {
  color: red;
}
.linkedin:hover {
  color: rgb(4, 4, 187);
}
@media(max-width: 800px) {
  .logo-inicio {
    align-self: initial;
  }
}
/* Inicio */
.pagina-inicio {
  font-family: 'Amatic SC', cursive;
  margin: 0rem 10rem;
  position: relative;
}
.oscuro {
  /* background-color: rgb(0, 0, 0, 0.7); */
  position: absolute;
  height: 100%;
}
.pagina-inicio :nth-child(n) {
  text-align: center;
  padding: 0.3rem 0rem;
}
a, a:hover{
  color: inherit;
  text-decoration: none;
  max-height: 200px;
}
.barra-enlaces {
  font-size: 20px;
  justify-content: center;
}
.barra-enlaces .row {
  clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0 100%);
  margin-top: -2em;
}
.barra-enlaces :nth-child(n) {
  font-family: 'Amatic SC', cursive;
  font-size: 3rem;
  text-shadow: 5px 10px rgb(0, 0, 0);
  align-items: center;
  justify-content: center;
  margin: 0;
  color: white;
  display: flex;
}
.barra-enlaces .enlace, video {
  width: 100%;
}
.video-inicio {
  display: flex;
  position: relative;
  opacity: 0.7;
}
.video-inicio:hover {
  opacity: 1;
}
.enlace {
  position: absolute;
  background-color: rgb(0, 0, 0, 0.5);
  height: 50vh;
}
@media(max-width: 992px) {
  .pagina-inicio {
    margin: 0 2rem;
  }
  .barra-enlaces {
    display: initial;
    flex-direction: column;
  }
  .barra-enlaces :nth-child(n) {
    width: 100%;
  }
  .video-inicio {
    opacity: 0.8;
    min-width: 100vw;
  }
  .iconos {
    left: 0.5em;
  }
}
/* Desarrollo Web, Ilustración*/
.cambio-enlace :nth-child(n) {
  justify-content: center;
}
.enlace-elegido {
  font-weight: bold;
  font-size: xx-large;
}
.enlace-no-elegido:hover {
  text-decoration: underline;
  cursor: pointer;
}
.link-externo {
  color: rgb(123, 123, 189);
  font-weight: bold;
}
/* Obra */
.obras {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  justify-content: center;
  align-content: flex-start;
}
.obras :nth-child(n) {
  justify-content: center;
/*   align-self: center; */
}
.obra {
  position: relative;
 /*  background-color: white; */
  width: 10rem;
  height: 20rem;
  cursor: pointer;
  margin: 0 0.5em;
}
.detalle {
  z-index: 1;
  align-self: center;
  display: flex;
  /* position: relative; */
  flex-direction: column;
  background-color: rgb(0, 0, 0, 0.7);
  color: white;
  width: 10rem;
  height: 20rem;
  opacity: 0;
  transition: opacity 2s;
}
.detalle:hover {
  opacity: 1;
}
.detalle:hover.oculto {
  opacity: 0;
}
.detalle :nth-child(n) {
  align-self: center;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
}
.oculto {
  display: none;
  opacity: 0;
}
.imagen-obra {
  position: absolute;
  align-self: center;
}
.web img {
  max-width: 20rem;
}
.translucido {
  opacity: 0.3;
  filter: blur(2px);
}
.imagen-obra img {
  width: 100%
}
.imagen-obra.web img {
  width: 9rem;
}
.imagen-obra.zoom {
 /*  display: flex; */
  height: 100vh;
  align-items: center;
  position: fixed;
  top: 0%;
  z-index: 2;
}
.aumentar {
  background-color: rgb(0, 0, 0, 0.7);
}
.aumentar img:nth-child(2) {
  max-width: 25rem;
  transition: 2s;
}
.imagen-obra.zoom img:first-child {
  align-self: flex-end;
  cursor: pointer;
  max-width: 2rem;
  z-index: 1;
}
@media(max-width: 800px) {
  .obras {
    position: relative;
  }
  .obra {
    height: initial;
  }
  /* .imagen-obra, .imagen-obra img, */ .detalle {
    min-width: 50vw;
  }
  .aumentar {
    opacity: 0;
    display: hidden;
  }
  .imagen-obra.zoom, .prueba {
    display: none;
  }
  .translucido {
    opacity: 1;
    filter: blur(0);
  }
  .imagen-obra.web  {
    position: initial;
    margin-bottom: 2rem;
  }
  .info-obra.web {
    position: absolute;
    height: 100%;
    margin-bottom: 2rem;
  }
  .detalle {
    min-width: 13rem;
  }
  .detalle :nth-child(n) {
    font-size: x-large;
  }
  .imagen-obra {
    box-shadow: 10px 10px 5px #ccc;
/*  margin-right: 0.5rem;
 */}
}


